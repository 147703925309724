import * as React from 'react';
import { useNavigate, useLocation  } from "react-router-dom"; 
import { useTranslation } from 'react-i18next';
 
export default function Footer(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
    const path = currentPath.substring(1);
 
    const home = () => { 
        navigate('/home');
    }; 
 
    const packages = () => { 
        navigate('/packages');
    }; 

    const record = () => { 
        navigate('/record');
    }; 

    const my = () => { 
        navigate('/my');
    }; 
 
    const { t } = useTranslation();

    return (
        <div className="nav-footer"> 
            <div onClick={home} className={`nav-footer-items ${path === 'home' ? 'active' : ''} `}>
                <span className="footer-icon">
                    <img src="/images/home.svg" alt={t('Home')} height="20"/>
                </span>
                <span>{t('Home')}</span>
            </div> 
            <div onClick={packages} className={`nav-footer-items ${path === 'shop' ? 'active' : ''} `}>
                <span className="footer-icon shop">
                    <img src="/images/shop.svg" alt={t('Shop')} height="20"/>
                </span>
                <span>{t('Shop')}</span>
            </div>
            <div onClick={record} className={`nav-footer-items ${path === 'record' ? 'active' : ''} `}>
                <span className="footer-icon">
                    <img src="/images/video.svg" alt={t('Record')} height="20"/>
                </span>
                <span>{t('Record')}</span>
            </div>
            <div onClick={my} className={`nav-footer-items ${path === 'my' ? 'active' : ''} `}>
                <span className="footer-icon">
                    <img src="/images/user.svg" alt={t('Profile')} height="20"/>
                </span>
                <span>{t('My')}</span>
            </div> 
        </div> 
      );
} 