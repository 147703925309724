
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'  
import Button from '@mui/material/Button'; 
import TextField from '@mui/material/TextField'; 
import InputAdornment from '@mui/material/InputAdornment';

import { GetBank, PostConvert, Profile } from '../../Services/User';  
  
import LoadingSpinner from '../LoadingSpinner'; 
import { useTranslation } from 'react-i18next'; 
 
import { Notyf } from "notyf";
const notyf = new Notyf({duration: 8000}); 

export default function Withdrawal() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate();  
    const [loading, setLoading] = React.useState(false)  
    const [isDisabled, setIsDisabled] = useState(true); 
    const [ password, setPassword] = useState(false);   
    const [ wallettype, setWallettype] = useState('');     
    const [ walletaddress, setWalletaddress] = useState('');     
    const [ balance, setBalance ] = useState("");   

    const [ canUsdt, setCanUsdt] = useState(true);   
    const [ canBtc, setCanBtc] = useState(false);   
    const [ canVisa, setCanVisa] = useState(false);   
    const [ canMaster, setCanMaster] = useState(false);   
    const [ canPaypal, setCanPaypal] = useState(false);   
  
    
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        UserProfile();   
  
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []); 

    const handleGoBack = () => {
        window.history.back();  
    };
     
    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data; 
            setBalance(data.balance2);
            if (data.pwd2 === null || data.pwd2 === "") { 
                notyf.error(t("Please set withdrawal password to continue"));   
                navigate('/withdraw-password'); 
            }  else { 
            }
                
        } 
    } 
 
    const handleConvert = async e => { 
        e.preventDefault();      
        let amount = parseFloat(balance); 
 
        setLoading(true);  
        let token = localStorage.getItem('token');  
       
        PostConvert({ 
            token,
            amount,
            password
        }, CallbackConvert);
    } 
 
    const CallbackConvert = (returnData) => {    
        setLoading(false); 
        var code = returnData.code; 
        if(code === 1)
        {  
            notyf.success(t(returnData.message));  
            navigate('/my');
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    } 
    
    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value)
    } 
     
    useEffect(() => {
        setIsDisabled(password === ''); 
    }, [password]);

    const cardStyle = { 
        backgroundImage: 'url(/images/balance_bg.png)',
        backgroundPosition: 'top center',    
        backgroundSize: 'contain',
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat'
    }; 
 
    return (
        <>
            {loading ? <LoadingSpinner /> : ''} 
            <div className="page-wrapper withdraw-wrapper">  
                <div className="inner-top-nav">
                    <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                    <span>{t('Convert to Balance')}</span>
                    <span></span>
                </div>
                <div className="form-wrapper">  
                     <form className="form" onSubmit={handleConvert} >   
                            
                        <div className="card app_wrapper" style={cardStyle}>  
                       
                            <div className="balance_info">
                                <h4>{t("Your Balance")}</h4>
                                <h2>${balance}</h2>
                                {/* <span className="btn-withdrawall" onClick={withdrawAll} >{t('withdraw all')}</span> */}
                            </div>  
                        </div>
                       
                        <div className="form-group">  
                            <label>{t("Enter Amount")}</label>
                            <div className="input-withdraw">
                                <span className="input_box">
                                    <span className="input_prefix">$</span>
                                    <input name="amount"  value={balance} required min="1" max={balance} readonly/>
                                </span>
                            </div>   
                        </div>
                        <div className="form-group">  
                            <label>{t("Convert to")}</label> 
                            <input className="form-control" value={t("Balance")} disabled/>
                        </div> 
  
                        <div className="form-group">  
                            <label>{t("Withdrawal Password")}</label>
                            <input className="form-control password" name="password" type="password"  onChange={(e)=>inputChangeHandler(setPassword, e)} required/>
                        </div>
  
                        <div className="form-action">   
                            <Button type="submit" className="btn btn-primary" disabled={isDisabled} variant="contained">{t('Convert Now')}</Button> 
                        </div>   
                    </form> 
                </div> 
            </div> 
        </>
    );
};