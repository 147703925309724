
import React from 'react';
import {Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; 
 
import '../Common.css';
import Footer from '../Layout/Footer';
import ImageCache from '../ImageCache';

export default function PageNotFound() { 
 
 
    const { t } = useTranslation();

  return ( 
    <>
    <div className="home-wrapper">
        <div className="content-wrapper">  
            <ImageCache loading="lazy"  src={"/images/logo-decode-cq.jpg"} alt="Decode CQ" width={120} className="home-logo"/> 
            <div className="page-content">
                <div className="banner">
                    <ImageCache loading="lazy"  src={"/images/error.png"} alt="Page not found" width={300} />
                </div>

                <div className="page-intro"> 
                    <h4>{t('404 - PAGE NOT FOUND')}</h4>
                    <p>{t('The page you are looking for might have been removed, had its name changed or is temporarily unavailable')}.</p>
                </div>

                <section className="module"> 
                    <Link to='/' className="btn btn-primary" replace >{t('Back To Home')}</Link >  
                </section> 
                
                <Footer/>
            </div>  
        </div>
    </div>
    </>
  );
};