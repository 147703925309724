
import React, {useEffect,useState} from 'react';

import { useNavigate } from 'react-router-dom'   
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
 
import useToken from '../App/useToken';
import Footer from '../Layout/Footer';
import { useTranslation } from 'react-i18next'; 

import { Profile, Pages, Banner, PageVersion, GetSupport } from '../../Services/User';  

export default function QnA() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const { token, setToken } = useToken();   
    const [supportUrl, setSupportUrl] = useState('');
    const [tgUrl, setTgUrl] = useState('');
    const [whatsappUrl, setWhatsappUrl] = useState('');
    const [ isOldPage, setIsOldPage ] = useState(false);  
 
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        }   
        PageVersion({}, CallbackPageVersion);  
        GetSupportInfo(); 

        if(isOldPage){ 
            GetPages();     
        }
        let pageContent = localStorage.getItem('pageContent');  
        if(pageContent){
            let parsedData = JSON.parse(pageContent);
            setList(parsedData);
        }
        // GetPages(); 
        // eslint-disable-next-line
    }, [isOldPage]); 
  

    const GetSupportInfo = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo;  
            // let pay_name = "trc20"; 
            GetSupport({}, CallbackGetSupport);  
        }
    } 
    
    const CallbackGetSupport = (returnData) => {  
        if(returnData.code == 1)
        {  
            var data = returnData.data; 
            setTgUrl(data.tg);   
            setSupportUrl(data.liveChat);   
            setWhatsappUrl(data.whatsapp);   
        }  
    } 



    const CheckPageVersion = async e  => {  
        PageVersion({}, CallbackPageVersion);  
    }
    
    const CallbackPageVersion = (returnData) => {  
        
        if(returnData.code === 1)
        { 
            var data = returnData.data;
 
            let pageVersion = localStorage.getItem('pageVersion');  
              
            if(pageVersion !== data.version){ 
                setIsOldPage(true);
                localStorage.setItem('pageVersion', data.version)
            }  
        }   
    } 

    const support = (e) => { 
        e.preventDefault();
        window.open(supportUrl, '_blank'); 
    }; 
    const tg = (e) => { 
        e.preventDefault();
        window.open(tgUrl, '_blank'); 
    }; 
    const wapps = (e) => { 
        e.preventDefault();
        window.open(whatsappUrl, '_blank'); 
    }; 

    const [open, setOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState(null);
    const [dialogTitle, setDialogTitle] = useState(null);

    const handleOpen = (id) => {
        let showContent = null;
        let showTitle = null;

        let content = getContent(id);

        if (content) {
            showTitle = content.title;
            showContent = content.content;
        } 

        setDialogTitle(showTitle);
        setDialogContent(showContent);
        setOpen(true);
    };

    const handleClose = () => {
    setOpen(false);
    };
 
    const GetPages = async e => {    
        Pages({
        }, CallbackPages);  
    } 
  
    const [ list, setList ] = useState([]);  

    const CallbackPages = (returnData) => {  
        if(returnData.code === 1)
        {    
            var data = returnData.data; 
            localStorage.setItem("pageContent", JSON.stringify(data)); 
            setList(data)
        } 
    } 

    function getContent(id) {
        // Get the stored JSON string from localStorage
        const storedList = localStorage.getItem("pageContent");
      
        // Parse the JSON string back into an array
        const list = JSON.parse(storedList) || [];
      
        // Find the object with the matching id
        const item = list.find((item) => item.id === id);
      
        return item;
    }

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };

  return (
    <>
        <div className="page-wrapper service-wrapper"> 
            <div className="inner-top-nav">
                <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                <span></span>
                <span></span>
            </div>
                
            <div className="module"> 
                <div className="box_wrapper">  
                    <div className="user_faq">  
                        <h4>{t("Q&A")}</h4>
                        <div className="user_faq_list">   
                            {list.length > 0 ? (
                                list.map(item => (  
                                    <div className="order-list-items" key={item.id} onClick={() => handleOpen(item.id)}> 
                                        <div className="order-list-item-title">{item.title}</div>
                                        <div className="order-list-item-value"> 
                                            <i className="fa-solid fa-chevron-right"></i>
                                        </div>
                                    </div> 
                                )) 
                            ) : (
                                <div className="records_tabs"> 
                                    <div className="text-center">{t('No record available')}</div> 
                            </div> 
                            )} 
                        </div>
                    </div>
                </div>
            </div>
  
        </div> 

        
        <Dialog
                className="dialog-wrapper"
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
            >
            <DialogTitle>{dialogTitle}</DialogTitle> 
            <DialogContent>
            <DialogContentText
                dangerouslySetInnerHTML={{ __html: dialogContent }}
                className="dialog-content"
                />
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
        
    </>
  );
};