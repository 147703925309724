
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'   
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText'; 

import { Profile } from '../../Services/User';  
  
import { useTranslation } from 'react-i18next'; 

import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function Account() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate();  
    const [ tel, setTel ] = useState("");   
    const [ realname, setRealname ] = useState("");  
    const [ lang, setLang ] = useState("");  
    
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        UserProfile();  
        
        // setLoading2(true);
        // setLoading(true);
        // eslint-disable-next-line
    }, []); 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
    
    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;
            setTel(data.tel);  
            setRealname(data.real_name); 
            setLang(data.lang); 
            
        } 
    } 

    const [openConfirm, setOpenConfirm] = React.useState(false);

    const handleLogout = () => {
        setOpenConfirm(true);
    };
  
    const handleCloseReject = () => {
        setOpenConfirm(false);
    };
    const handleCloseConfirm = () => {
        setOpenConfirm(false); 
        logout();
    };
    const logout = () => {
        localStorage.clear();
        navigate('/login');
    };

    const handleBank = () => { 
        navigate('/bank');
    }; 
    const handlePassword = () => { 
        navigate('/password');
    }; 
    const handlePasswordWithdraw = () => { 
        navigate('/withdraw-password');
    }; 
    
    const handleLanguage = () => { 
        navigate('/language');
    }; 

    const handleRealname = () => { 
        navigate('/realname');
    }; 
 
    return (
        <>
            <div className="page-wrapper account-wrapper">  
                <div className="inner-top-nav">
                    <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                    <span>{t('Account')}</span>
                    <span></span>
                </div>
                <div className="account-lists"> 
                    <div className="order-list-items"> 
                        <div className="order-list-item-title">{t('Avatar')}</div>
                        <div className="order-list-item-value">
                            <img src={"/images/head.png"} height={30} />
                            <i className="fa-solid fa-chevron-right"></i>
                        </div>
                    </div> 
                    <div className="order-list-items"> 
                        <div className="order-list-item-title">{t('Phone Number')}</div>
                        <div className="order-list-item-value">
                            {tel}
                            <i className="fa-solid fa-chevron-right"></i>
                        </div>
                    </div>  
                    <div className="order-list-items" onClick={handleRealname}> 
                        <div className="order-list-item-title">{t('Real Name')}</div>
                        <div className="order-list-item-value">
                            {realname ? (
                                <>
                                    {realname}
                                </>
                            ) : (
                                <>
                                    <span>{t('Set')}</span>
                                </>
                            )}
                            <i className="fa-solid fa-chevron-right"></i>
                        </div>
                    </div> 
                    <div className="order-list-items" onClick={handleBank}> 
                        <div className="order-list-item-title">{t('Bank Info')}</div>
                        <div className="order-list-item-value">
                            <i className="fa-solid fa-chevron-right"></i>
                        </div>
                    </div> 
                    <div className="order-list-items" > 
                        <div className="order-list-item-title">{t('Password management')}</div>
                        <div className="order-list-item-value"> 
                            <i className="fa-solid fa-chevron-right"></i>
                        </div>
                    </div> 
                    <div className="order-list-items" > 
                        <div className="order-list-item-title">{t('Withdraw Password management')}</div>
                        <div className="order-list-item-value"> 
                            <i className="fa-solid fa-chevron-right"></i>
                        </div>
                    </div> 
                    <div className="order-list-items" onClick={handleLanguage}> 
                        <div className="order-list-item-title">{t('Language')}</div>
                        <div className="order-list-item-value">
                            {lang}
                            <i className="fa-solid fa-chevron-right"></i>
                        </div>
                    </div> 
                    <div className="order-list-items" onClick={handleLogout}> 
                        <div className="order-list-item-title">{t('Logout')}</div>
                        <div className="order-list-item-value">
                            <i className="fa-solid fa-chevron-right"></i>
                        </div>
                    </div> 
                </div>
            </div>
            
            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            > 
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                {t('Are you sure to exit??')}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseReject}>{t('Cancel')}</Button>
                <Button onClick={handleCloseConfirm} autoFocus>
                {t('Confirm')}
                </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};