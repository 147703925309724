
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'  
import { experimentalStyled as styled } from '@mui/material/styles'; 
import Button from '@mui/material/Button';
  
import { GetOrder, GetOrderDetail, PostOrderDetail } from '../../Services/User';  
import useToken from '../App/useToken';
import Footer from '../Layout/Footer';
import ListComponent from './OrderComponent';  
import Alert from '@mui/material/Alert';
import LoadingSpinner from '../LoadingSpinner'; 
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent'; 
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide'; 
 
import OrderdetailComponent from './OrderdetailComponent';
import { useTranslation } from 'react-i18next'; 

import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
  

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));
  
export default function Record() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const { token, setToken } = useToken();  
    const [iswaiting, setIswaiting] = useState(false); 
    const [iscomplete, setIscomplete] = useState(false); 
    const [isall, setIsall] = useState(true); 
    const [list, setList] = useState([]);
    const [orderInfo, setOrderInfo] = useState(false);
    const [orderDetail, setOrderDetail] = useState([]);
    const [orderError, setOrderError] = useState(false);
    const [orderErrorMessage, setOrderErrorMessage] = useState([]);
    const [postorderBtn, setPostorderBtn] = useState(false);// idle, submitting, allocated, waiting
    const [countdown2, setCountdown2] = useState(0);

    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }  
        getAllOrder();
        // eslint-disable-next-line
    }, []); 
 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
    
    const getAllOrder = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            setIsall(true);
            setIswaiting(false);
            setIscomplete(false);
            setList([]); 
            let token   = tokenInfo; 
            let type    = 'all'; 
            GetOrder({
                type,
                token
            }, CallbackWaiting);  
        }
    } 
    
    const getWaitingOrder = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            setIsall(false);
            setIswaiting(true);
            setIscomplete(false);
            setList([]); 
            let token   = tokenInfo; 
            let type    = 'waiting'; 
            GetOrder({
                type,
                token
            }, CallbackWaiting);  
        }
    } 
    
    const CallbackWaiting = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var lists = returnData.data;  
            setList(lists);
        } 
    } 
 
    const getCompletedOrder = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            setIsall(false);
            setIscomplete(true);
            setIswaiting(false);
            setList([]); 
            let token = tokenInfo; 
            let type    = 'complete'; 
            GetOrder({
                type,
                token
            }, CallbackComplete);  
        }
    } 
    
    const CallbackComplete = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var lists = returnData.data; 
            setList(lists); 
        } 
    } 
 
    const getOrderInfo = (order_number) => {    
        navigate('/order/'+order_number);

        // let tokenInfo = localStorage.getItem('token');  
        // if(tokenInfo === null){ 
        //     navigate('/login');
        // } 
        // else {   
        //     let token = tokenInfo; 
        //     let oid   = order_number; 
        //     GetOrderDetail({
        //         oid,
        //         token
        //     }, CallbackOrderInfo);  
        // }
    } 
    
    const CallbackOrderInfo = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var lists = returnData.data;  
            setOrderInfo(true);
            setOrderDetail(lists);
        } 
    } 
 
    const [status, setStatus] = useState('idle');  
    const [loading3, setLoading3] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const countdownDelay = async () => {
        for (let i = 3; i >= 1; i--) {
            setCountdown2(i);
            await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second
        }
    };

    const postOrder = async (order_number) => {    
        
        setPostorderBtn(true)
        setOrderError(false)
        setOrderErrorMessage("")

        setLoading3(true);
        setStatus(t('submitting...'));
        setCountdown2(2);

        // Countdown before the next step
        await countdownDelay();
        
        setStatus(t('The remote host is being allocated...'));
        setCountdown2(4);

        // Countdown before the next step
        await countdownDelay();

        setStatus(t('Waiting for the merchant system to respond'));
        setCountdown2(3);

        // Countdown before sending the API request
        await countdownDelay();
        
        try {
            // Call your sendapi function here
            let tokenInfo = localStorage.getItem('token');  
            if(tokenInfo === null){ 
                navigate('/login');
            } 
            else {    
                setLoading3(false);
                setLoading2(true);
                let token = tokenInfo; 
                let oid   = order_number; 
                await PostOrderDetail({
                    oid,
                    token
                }, CallbackPostOrder);  
            }
        } catch (error) {
            console.error(error); // Handle the error
        }
    
        // Reset status and countdown after completing the process
        setStatus('');
        setCountdown2(0);  
    } 
    
    const CallbackPostOrder = (returnData) => {   
        setLoading2(false);
        setLoading3(false);
        setPostorderBtn(false); 

        if(returnData.code !== 1)
        {  
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
            else{
                setOrderError(true);
                setOrderErrorMessage(returnData.message);
                notyf.error(returnData.message); 
            }
        } 
        else{  
            var lists = returnData.data;  
            setOrderDetail([]);
            notyf.success(t('Success')); 
            getWaitingOrder(); 
            setOrderInfo(false);
        } 
    } 

    const handleCloseConfirm = () => {
        setOrderInfo(false);
    };
    return (
    <>
        <div className="page-wrapper record-wrapper">  
            <div className="module app_wrapper"> 
                <div className="page-title"> 
                    <h4>{t("Record")}</h4>
                </div>

                <div className="inner-tab-nav">
                    <span 
                        className={`nav-tab ${isall ? 'active' : ''}`} 
                        onClick={getAllOrder}>{t('All')}
                    </span> 
                    <span 
                        className={`nav-tab ${iswaiting ? 'active' : ''}`} 
                        onClick={getWaitingOrder}>{t('Waiting')}
                    </span> 
                    <span 
                        className={`nav-tab ${iscomplete ? 'active' : ''}`}  
                        onClick={getCompletedOrder}>{t('Completed')}
                    </span>  
                </div>

                <div className="record-lists">   
                {list !== null ? (
                    list.map(item => ( 
                        <ListComponent item={item} key={item.orderNumber} getOrderInfo={getOrderInfo} />
                    )) 
                ) : (
                    <div className="info-alert">{t('No record available')}</div>
                )}
                </div> 
            
            </div>
            
            {loading2 ? <LoadingSpinner /> : ''}

            {loading3 ? (
                
                <div className="loading">
                    <div className="loading-text">
                        <div>
                        {status} 
                        </div>
                    </div>
                </div>
                
            ) :  '' }
    
            <BootstrapDialog
                onClose={handleCloseConfirm}
                aria-labelledby="customized-dialog-title"
                open={orderInfo}
                >
                <DialogTitle className="text-center"> 
                {"Connection successful"}
                </DialogTitle> 
                <DialogContent dividers> 
                <>
                    <OrderdetailComponent item={orderDetail} /> 
                    {orderError ?  
                    <Alert severity="error" className="mt-3 mb-3">{orderErrorMessage}</Alert> 
                    : ''} 
                </>         
                </DialogContent>
                <DialogActions>
                    <Button className="btn btn-primary" onClick={() => postOrder(orderDetail.orderNumber)}  autoFocus   size="sm" type="submit" disabled={postorderBtn}  variant="contained">
                    {t('Confirm')}
                    </Button>
                    <Button className="btn btn-danger" onClick={handleCloseConfirm}  variant="outlined">{t('Cancel')}</Button>
                </DialogActions>
            </BootstrapDialog>
                 
        </div>
        <Footer/>
    </>
    );
};