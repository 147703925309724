export default function LoadingSpinner() {
 
  return (
    <>
      <div className="loading">
      <span>
        <i className="fa-solid fa-spinner fa-spin"></i>
        <p>Loading</p>
      </span>
      </div>
    </> 
  );
}
