import React from 'react';
import { useTranslation } from 'react-i18next'; 
 
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
 

const SliderCarousel = ({ imageData }) => {

    const { t } = useTranslation();
     
  
    const imageDataArray = Object.values(imageData);
        
    if (imageDataArray.length === 0) {
        return ( <></>);
    }
    
    const settings = {  
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 5,
        slidesToScroll: 1,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 5, 
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3, 
              initialSlide: 3
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2
            }
          }
        ]
      };
  return ( 
      <div className="module-slider">
          <Slider {...settings}>
            
          {imageDataArray.map(item => (
            <div className="slider-item" key={item.id}>
              <div className="slider-item-info">
                <div className="slider-item-image">
                    <img src={item.image} alt={`${item.title}`} width="100" /> 
                </div>
              </div>
            </div> 
          ))} 
          </Slider>
      </div> 
  );
}

export default SliderCarousel;
