import React from 'react'; 
import Button from '@mui/material/Button';
import OrderdetailComponent from './OrderdetailComponent';
import { useTranslation } from 'react-i18next'; 

function OrderComponent({ item, getOrderInfo }) {
 
  const { t } = useTranslation();
  return (
    <div>
        <div className="records_item"  key={item.orderNumber}>
          {item !== null ? ( 
            <OrderdetailComponent item={item} /> 
          ):''}
            {item.status === 0 ? ( 
              <Button onClick={() => getOrderInfo(item.orderNumber)}  size="sm" type="submit"  variant="contained">{t('submit order')}</Button> 
              ) : (
                ''
                )}
        </div>
    </div>
  );
}

export default OrderComponent;
