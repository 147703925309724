
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'   
  
import { useTranslation } from 'react-i18next';

import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function About() {  

    const { t } = useTranslation();
    const navigate = useNavigate();  
    const [ langcode, setLangcode ] = useState("en");  
    
    useEffect(() => {   
        const storedLang = localStorage.getItem('lang'); 
        if (storedLang) {
            setLangcode(storedLang);
        } 
    }, []); 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
     
  return (
    <>
        <div className="page-wrapper qna-wrapper"> 
            <div className="inner-top-nav">
                <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                <span></span>
                <span></span>
            </div> 
            <div className="content">
                <img src={`/images/poster/${langcode}.jpg`} alt={`Q&A`} />          
            </div> 
   
        </div> 
    </>
  );
};