
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'  
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'; 

import { Profile, SetName } from '../../Services/User';  
  
import LoadingSpinner from '../LoadingSpinner'; 
import { useTranslation } from 'react-i18next';

import { Notyf } from "notyf";
const notyf = new Notyf({duration: 5000}); 

export default function Realname() {  
    const navigate = useNavigate();  
    const { t } = useTranslation();
 
    const [loading, setLoading] = React.useState(false)  
    const [isDisabled, setIsDisabled] = useState(true);
    
    useEffect(() => {  
        let tokenInfo = localStorage.getItem('token');   
        if(tokenInfo === null){ 
            navigate('/login');
        }    
        UserProfile();   
    }, []); 

    const handleGoBack = () => {
        window.history.back(); // Navigate back to the previous page
    };
    
    const UserProfile = async e => {    
        let tokenInfo = localStorage.getItem('token');  
        if(tokenInfo === null){ 
            navigate('/login');
        } 
        else {  
            let token = tokenInfo; 
            Profile({
                token
            }, CallbackProfile);  
        }
    } 
    
    const CallbackProfile = (returnData) => {  
        if(returnData.code !== 1)
        { 
            if(returnData.code === 3001 || returnData.code === 3002 )
            { 
                localStorage.removeItem('token'); 
                localStorage.removeItem('userdata'); 
                navigate('/login');
            }  
        } 
        else{  
            var data = returnData.data;  
            if(data.real_name != null && data.real_name != ''){ 
                setRealname(data.real_name); 
                setCanupdate(false); 
            }
            else{
                setCanupdate(true); 
            }
        } 
    } 
 
    const [canupdate, setCanupdate] = useState(false);    
    const [ realname, setRealname ] = useState("");   
  
    const handleSetName = async e => { 
        e.preventDefault();      
        setLoading(true);  
        let token = localStorage.getItem('token');  
        let real_name = realname;
        SetName({ 
            token,
            real_name
        }, CallbackSetName);
    } 
 
    const CallbackSetName = (returnData) => {    
        setLoading(false); 
        var code = returnData.code; 
        if(code === 1)
        { 
            var data = returnData.data; 
            UserProfile();  
        }
        else{ 
            notyf.error(returnData.message); 
        } 
    } 
    
    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value)
    } 
     
    useEffect(() => {
        setIsDisabled(realname === ''); 
    }, [ realname ]);

    return (
        <>
            {loading ? <LoadingSpinner /> : ''} 
            <div className="page-wrapper account-wrapper">  
                <div className="inner-top-nav">
                    <span className="btn-back" onClick={handleGoBack}><i className="fa-solid fa-chevron-left"></i></span>
                    <span>{t('Set Real Name')}</span>
                    <span></span>
                </div>
                <div className="form-wrapper">  
                    <form className="form" onSubmit={handleSetName} >   
                        <div className="form-group">  
                            <TextField size="small" value={realname} id="realname" label={t("Real Name")} variant="outlined"  onChange={(e)=>inputChangeHandler(setRealname, e)}  disabled={!canupdate}/>
                        </div> 
                         
                        <div className="form-action">  
                            {canupdate ? (
                                <Button type="submit" className="btn btn-primary" disabled={isDisabled} variant="contained">{t("Submit")}</Button> 
                            ) : (
                                <div className="form-alert">{t("Please contact admin for update")}</div>
                            )}
                        </div>   
                    </form> 
                </div>
            </div> 
        </>
    );
};